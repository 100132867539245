import React, { useState, useEffect } from 'react';
import ENDPOINT from '../../constants/api-endpoints';

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Banner from "../elements/Banner";
import ContactForm from '../elements/ContactForm';

var parse = require('html-react-parser');

const AboutUsVideoBlock = (props) => {
    const value = props.value;
    return (
        <>
            {parse(value.content)}
        </>
    );
}

const AboutUsBlock = (props) => {
    const value = props.value;
    return (
        <>
            <h1>{value.title}</h1>
            {parse(value.content)}
        </>
    );
}

const GuideBlock = (props) => {
    const value = props.value;
    return (
        <div className="row">
            {value.data.map((item, i) => {
                return (
                    <div className="col-sm-4" key={i}>
                        <h3>{item.title}</h3>
                        {parse(item.content)}
                    </div>
                );
            })};
        </div>
    );
}

const TestimonialBlock = (props) => {
    const value = props.value;
    return (
        <>
            <div className="testimonial4_header">
                <h4>{value.heading}</h4>
            </div>
            <div className="">
                <OwlCarousel id="testimonial4" className="testimonial4_slide owl-carousel owl-theme row" responsive={{0:{items:1},600:{items:1},1000:{items:3}}} loop mergeFit items={3} autoplay>
                    {value.data.map((item, i) => {
                        return (
                            <div className="col-md-10" key={i}>
                                <div className="texti-content">
                                    <img src={item.image} />
                                    {parse(item.content)}
                                    <h4>{item.title}</h4>
                                </div>
                            </div>
                        );
                    })}
                </OwlCarousel>
            </div>
        </>
    );
}

const BlogRightSideBlock = (props) => {
    const value = props.value;
    return (
        <div className="video">
            {parse(value.content)}
        </div>
    );
}

const BlogBlock = (props) => {
    const value = props.value;
    return (
        <div className="blog">
            <h2>{value.heading}</h2>
            <ul>
                {value.data.map((item, i) => {
                    return (
                        <li key={i}>
                            <a href="#">
                                {parse(item.content)}
                            </a>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
}

const Home = () => {
    const [homepageData, setHomepage] = useState([]);
    useEffect(() => {
        fetch(ENDPOINT.HOMEPAGE_DATA)
            .then((response) => response.json())
            .then((responseData) => {
                setHomepage(responseData);
            })
            .catch((err) => {
                console.log(err.message);
            });
    }, []);
    let aboutUsVideoBlock = '';
    if (homepageData.aboutUsBlock) {
        aboutUsVideoBlock = <AboutUsVideoBlock value={homepageData.aboutUsVideoBlock} />;
    }
    let aboutUsBlock = '';
    if (homepageData.aboutUsBlock) {
        aboutUsBlock = <AboutUsBlock value={homepageData.aboutUsBlock} />;
    }
    let guideBlock = '';
    if (homepageData.guideList) {
        guideBlock = <GuideBlock value={homepageData.guideList} />;
    }
    let testimonialBlock = '';
    if (homepageData.testimonialList) {
        testimonialBlock = <TestimonialBlock value={homepageData.testimonialList} />;
    }
    let blogRightSideBlock = '';
    if (homepageData.blogRightBlock) {
        blogRightSideBlock = <BlogRightSideBlock value={homepageData.blogRightBlock} />;
    }
    let blogBlock = '';
    if (homepageData.blogList) {
        blogBlock = <BlogBlock value={homepageData.blogList} />;
    }
    return (
        <>
            <div className="banner-slider">
                <Banner />    
            </div>
            <div className="welcome-content">
                <div className="container pad-top50 mainbody-bg">
                    <div className="row">
                        <div className="col-sm-6">
                            {aboutUsVideoBlock}
                        </div>
                        <div className="col-sm-6">
                            {aboutUsBlock}
                        </div>
                    </div>
                </div>
            </div>
            <div className="midsec1">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-3">
                            <a href="/page/referring-dentists">
                                <div className="circle">
                                    <i className="fa fa-retweet" aria-hidden="true"></i>
                                </div>
                                <h3>Referring Dentist</h3>
                            </a>
                        </div>
                        <div className="col-sm-3">
                            <a href="/page/gentle-wave">
                                <div className="circle">
                                    <img src={`${process.env.PUBLIC_URL}/assets/img/GW-toplogo-wbg.png`} alt="" />
                                </div>
                                <h3>Gentle Wave</h3>
                            </a>
                        </div>
                        <div className="col-sm-3">
                            <a href="/contact">
                                <div className="circle">
                                    <i className="fa fa-map"></i>
                                </div>
                                <h3>Contact Us</h3>
                            </a>
                        </div>
                        <div className="col-sm-3">
                            <a href="/gallery/image">
                                <div className="circle">
                                    <i className="fa fa-transgender-alt"></i>
                                </div>
                                <h3>Take a Tour</h3>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="midsec2">
                <div className="container mainbody-bg">
                    {guideBlock}
                    <div className="row testimonial">
                        {testimonialBlock}
                    </div>
                    <div className="bottom_block">
                        <div className="row">
                            <div className="col-sm-4">
                                {blogRightSideBlock}
                            </div>
                            <div className="col-sm-4">
                                {blogBlock}
                            </div>
                            <div className="col-sm-4">
                                <div className="feedback">
                                    <ContactForm />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
  
export default Home;
