import { useParams } from 'react-router-dom'
import React, { useState, useEffect, useMemo } from 'react';
import ENDPOINT from '../../constants/api-endpoints';
import { ProgressBar } from 'react-loader-spinner'
import { Link } from 'react-router-dom';

var parse = require('html-react-parser');

const Page = () => {
    let { slug } = useParams();
    let [pageData, setPage] = useState([]);
    useEffect(() => {
        fetch(ENDPOINT.PAGE_DATA + '/' + slug)
            .then((response) => response.json())
            .then((responseData) => {
                setPage(responseData);
            })
            .catch((err) => {
                console.log(err.message);
            });
    }, []);
    let pageTitle = '';
    let pageContent = '';
    if (pageData.length !== 0) {
        pageTitle = pageData.data.title;
        pageContent = pageData.data.content;
        if (!pageContent.data) {
            return (
                <>
                    <div className="innerpage-banner">
                        <Link to="/">
                            <img src={`${process.env.PUBLIC_URL}/assets/img/blog.jpeg`} alt="" />
                        </Link>
                    </div>
                    <div className="container">
                        <div className="innerpage">
                            <h1>{pageTitle}</h1>
                            {parse(pageContent)} 
                        </div>
                    </div>
                </>
            );
        } else {
            return (
                <div className="container">
                    <div className="innerpage">
                        <center>
                        <ProgressBar
                                height="80"
                                width="80"
                                ariaLabel="progress-bar-loading"
                                wrapperStyle={{textAlign: 'center', justifyContent: 'center'}}
                                wrapperClass="progress-bar-wrapper"
                                borderColor = '#f8de00'
                                barColor = '#570101'
                            />
                        </center>
                    </div>
                </div>
            );
        }
    } else {
        return (
            <div className="container">
                <div className="innerpage">
                    <center>
                    <ProgressBar
                            height="80"
                            width="80"
                            ariaLabel="progress-bar-loading"
                            wrapperStyle={{textAlign: 'center', justifyContent: 'center'}}
                            wrapperClass="progress-bar-wrapper"
                            borderColor = '#f8de00'
                            barColor = '#570101'
                        />
                    </center>
                </div>
            </div>
        );
    }
};
  
export default Page;
