import { Routes, Route } from 'react-router-dom';
import Layout from './Layout';
import Home from './routes/Home';
import Blog from './routes/Blog';
import BlogDetail from './routes/BlogDetail';
import Testimonial from './routes/Testimonial';
import Page from './routes/Page';
import Contact from './routes/Contact';
import MetaData from './elements/MetaData';
import Gallery from './routes/Gallery';

const Skeleton = () => {
  return (
    <>
      <MetaData />
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="blogs" element={<Blog />} />
          <Route path="blogs/:slug" element={<BlogDetail />} />
          <Route path="page/:slug" element={<Page />} />
          <Route path="testimonials" element={<Testimonial />} />
				  <Route exact path="contact" element={<Contact />} />
				  <Route exact path="gallery/image" element={<Gallery />} />
          <Route path="*" element={<p>Not found!</p>} />
        </Route>
      </Routes>
    </>
  );
};

export default Skeleton;