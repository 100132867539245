import React, { useState, useEffect } from 'react';
import Navbar from '../menu/Navbar';
import ENDPOINT from '../../constants/api-endpoints';
import { Link } from 'react-router-dom';

const Header = () => {
    const [settingsData, setSettings] = useState([]);
    useEffect(() => {
        fetch(ENDPOINT.SETTINGS_DATA)
            .then((response) => response.json())
            .then((responseData) => {
                setSettings(responseData.data);
            })
            .catch((err) => {
                console.log(err.message);
            });
    }, []);
    let siteName = '';
    let siteLogo = '';
    let phone = '';
    let socialFacebook = '';
    let socialYelp = '';
    let socialYoutube = '';
    let socialGoogle = '';
    let socialTwitter = '';
    let appointmentLink = '';
    if (settingsData.length !== 0) {
        settingsData.map((item, i) => {
            if (item.slug === 'site-name') { siteName = item.content; }
            if (item.slug === 'site-logo') { siteLogo = item.content; }
            if (item.slug === 'phone') { phone = item.content; }
            if (item.slug === 'facebook') { socialFacebook = item.content; }
            if (item.slug === 'yelp') { socialYelp = item.content; }
            if (item.slug === 'youtube') { socialYoutube = item.content; }
            if (item.slug === 'google') { socialGoogle = item.content; }
            if (item.slug === 'twitter') { socialTwitter = item.content; }
            if (item.slug === 'book-a-appointment') { appointmentLink = item.content; }
        });
    }
    return (
        <header>
            <div className="topblock">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-6">   
                            <ul className="top-left-links">
                                {socialFacebook &&
                                    <li>
                                        <a target="_blank" href={socialFacebook} rel="noopener noreferrer">
                                            <i className="fa fa-facebook" aria-hidden="true"></i>
                                        </a>
                                    </li>
                                }
                                {socialYelp &&
                                    <li>
                                        <a target="_blank" href={socialYelp} rel="noopener noreferrer">
                                            <i className="fa fa-yelp" aria-hidden="true"></i>
                                        </a>
                                    </li>
                                }
                                {socialYoutube &&
                                    <li>
                                        <a target="_blank" href={socialYoutube} rel="noopener noreferrer">
                                            <i className="fa fa-youtube-play" aria-hidden="true"></i>
                                        </a>
                                    </li>
                                }
                                {socialGoogle &&
                                    <li>
                                        <a target="_blank" href={socialGoogle} rel="noopener noreferrer">
                                            <i className="fa fa-google-plus" aria-hidden="true"></i>
                                        </a>
                                    </li>
                                }
                                {socialTwitter &&
                                    <li>
                                        <a target="_blank" href={socialTwitter} rel="noopener noreferrer">
                                            <i className="fa fa-twitter" aria-hidden="true"></i>
                                        </a>
                                    </li>
                                }
                            </ul>
                        </div>
                        <div className="col-sm-6">
                            <p>Make an Appointment Now! <Link to={appointmentLink}>  Click Here!</Link></p>
                        </div>
                    </div>           
                </div>
            </div>
            <div className="container mainbody-bg">
                <div className="row">
                    <div className="col-sm-5">
                        <div className="logo">
                            <Link to="/">
                                <img 
                                    src={siteLogo}
                                    alt=""  
                                />
                            </Link>
                        </div>
                    </div>
                    <div className="col-sm-7">
                        <div className="phone-number">
                            Call Today:
                            <span>{phone}</span>
                        </div>
                        <div className="right-logo">
                            <a href='http://aae.com'>
                                <img 
                                    src={`${process.env.PUBLIC_URL}/assets/img/logo-right.png`}
                                    alt=""  
                                />
                            </a>
                        </div>
                        <div className="right-logo">
                            <a href='https://gentlewave.com/'>
                                <img  
                                    src={`${process.env.PUBLIC_URL}/assets/img/GW-toplogo.png`}
                                    alt="" 
                                />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <Navbar />
        </header> 
    );
};

export default Header;