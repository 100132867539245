import React, { useState, useEffect } from 'react';

import ENDPOINT from '../../constants/api-endpoints';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';


var parse = require('html-react-parser');

const Banner = () => {
    const [bannerData, setPosts] = useState([]);
    useEffect(() => {
        fetch(ENDPOINT.BANNER_LIST)
            .then((response) => response.json())
            .then((responseData) => {
                setPosts(responseData.data);
            })
            .catch((err) => {
                console.log(err.message);
            });
    }, []);
    return (
        <OwlCarousel id="myCarousel" className='owl-theme carousel slide carousel-inner' data-ride="carousel" autoplay loop items={1}>
            {bannerData.map((item, i) => {
                return (
                    <div className="item" key={i}>
                        <img src={item.imageLink} alt={item.title} style={{width: '100%'}} />
                        <div class="carousel-caption">
                            <div class="caption-background">
                                {parse(item.caption)}
                            </div>
                            {item.link && <a href={item.link} target="_blank">{item.linkName}</a>}
                        </div>
                    </div>
                );
            })}
        </OwlCarousel>
    );
}

export default Banner;
