import React, { useState, useEffect } from 'react';

import ENDPOINT from '../../constants/api-endpoints';
import { Link } from 'react-router-dom';
import NewsletterForm from '../elements/NewsletterForm';

var parse = require('html-react-parser');

const RefDentistBlock = (props) => {
    const value = props.value;
    return (
        <>
            <h3>{value.title}</h3>
            <p>{value.content}</p>
        </>
    );
}

const AddressBlock = (props) => {
    const value = props.value[0];
    return (
        <>
            <h3>{value.title}</h3>
            <p>{value.content}</p>
            <h3>Our Location</h3>
            <p>
                <span>
                    <i className="fa fa-map-marker"></i>
                </span>
                {value.addressLine1}<br />
                {value.addressLine2}, {value.city}<br />
                {value.state} - {value.pincode}
            </p>
        </>
    );
}

const Footer = () => {
    const goNav = (goto) => {
        window.location.href = goto;
    };
    const [settingsData, setSettings] = useState([]);
    useEffect(() => {
        fetch(ENDPOINT.SETTINGS_DATA)
            .then((response) => response.json())
            .then((responseData) => {
                setSettings(responseData.data);
            })
            .catch((err) => {
                console.log(err.message);
            });
    }, []);
    let siteName = '';
    let phone = '';
    let socialFacebook = '';
    let socialYelp = '';
    let socialYoutube = '';
    let socialGoogle = '';
    let socialTwitter = '';
    let referringDentist = '';
    if (settingsData.length !== 0) {
        settingsData.map((item, i) => {
            if (item.slug === 'site-name') { siteName = item.content; }
            if (item.slug === 'phone') { phone = item.content; }
            if (item.slug === 'facebook') { socialFacebook = item.content; }
            if (item.slug === 'yelp') { socialYelp = item.content; }
            if (item.slug === 'youtube') { socialYoutube = item.content; }
            if (item.slug === 'google') { socialGoogle = item.content; }
            if (item.slug === 'twitter') { socialTwitter = item.content; }
            if (item.slug === 'referring-dentist') { referringDentist = <RefDentistBlock value={item} />; }
        });
    }
    const [addressData, setAddress] = useState([]);
    useEffect(() => {
        fetch(ENDPOINT.ADDRESS_DATA)
            .then((response) => response.json())
            .then((responseData) => {
                setAddress(responseData.data);
            })
            .catch((err) => {
                console.log(err.message);
            });
    }, []);
    let addressBlock = '';
    if (addressData.length !== 0) {
        addressBlock = <AddressBlock value={addressData} />;
    }
    const [menuData, setMenus] = useState([]);
    useEffect(() => {
        fetch(ENDPOINT.NAV_MENU)
            .then((response) => response.json())
            .then((responseData) => {
                setMenus(responseData);
            })
            .catch((err) => {
                console.log(err.message);
            });
    }, []);
    let listItems = [];
    if (menuData.length !== 0) {
        const hNav = menuData.Footer;
        listItems = hNav;
    }
    return (
        <footer>
            <div className="container">
                <div className="row">
                    <div className="col-sm-3">
                        {referringDentist}
                    </div>
                    <div className="col-sm-3">
                        <h3>Important Links</h3>
                        <ul className="foot-links">
                            {listItems.map((menu, index) => {
                                return (
                                    <li>
                                        <a onClick={() => goNav(menu.url) }>{menu.title}</a>
                                    </li> 
                                );
                            })};   
                        </ul>
                    </div>			
                    <div className="col-sm-3">
                        <NewsletterForm />
                    </div>
                    <div className="col-sm-3">
                        {addressBlock}
                        <p><span><i className="fa fa-phone"></i></span>{phone}</p>
                        <h3 className="footersub">Follow Us</h3>
                        <ul className="socialicons">
                            {socialFacebook &&
                                <li>
                                    <a target="_blank" href={socialFacebook} rel="noopener noreferrer">
                                        <i className="fa fa-facebook" aria-hidden="true"></i>
                                    </a>
                                </li>
                            }
                            {socialYelp &&
                                <li>
                                    <a target="_blank" href={socialYelp} rel="noopener noreferrer">
                                        <i className="fa fa-yelp" aria-hidden="true"></i>
                                    </a>
                                </li>
                            }
                            {socialYoutube &&
                                <li>
                                    <a target="_blank" href={socialYoutube} rel="noopener noreferrer">
                                        <i className="fa fa-youtube-play" aria-hidden="true"></i>
                                    </a>
                                </li>
                            }
                            {socialGoogle &&
                                <li>
                                    <a target="_blank" href={socialGoogle} rel="noopener noreferrer">
                                        <i className="fa fa-google-plus" aria-hidden="true"></i>
                                    </a>
                                </li>
                            }
                            {socialTwitter &&
                                <li>
                                    <a target="_blank" href={socialTwitter} rel="noopener noreferrer">
                                        <i className="fa fa-twitter" aria-hidden="true"></i>
                                    </a>
                                </li>
                            }
                        </ul>
                    </div>
                </div>
            </div>
            <section className="copyright">
                {new Date().getFullYear()} &copy; All Rights Reserved | {siteName} 
            </section>
        </footer> 
    );
}

export default Footer;