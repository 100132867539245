import Settings from '../constants/settings';
const apiEndPoint = Settings.API_END_POINT_URL || {};
const appUrl = Settings.APP_URL || {};
const apiUrls = {
    BASE_PATH: `${apiEndPoint}`,
    APP_BASE_PATH: `${appUrl}`,
    HOMEPAGE_DATA: `${apiEndPoint}/api/v1/homepageData`,
    BANNER_LIST: `${apiEndPoint}/api/v1/bannerList`,
    NAV_MENU: `${apiEndPoint}/api/v1/navigationMenu`,
    PAGE_DATA: `${apiEndPoint}/api/v1/pageData`,
    CONTACT_SUBMIT: `${apiEndPoint}/api/v1/contactSubmit`,
    ADDRESS_DATA: `${apiEndPoint}/api/v1/addressData`,
    BLOG_DATA: `${apiEndPoint}/api/v1/blogData`,
    TESTIMONIAL_DATA: `${apiEndPoint}/api/v1/testimonialData`,
    SETTINGS_DATA: `${apiEndPoint}/api/v1/settingsData`,
    NEWSLETTER_SUBMIT: `${apiEndPoint}/api/v1/newsletterSubmit`,
    META_DATA: `${apiEndPoint}/api/v1/metaData`,
    IMAGES_DATA: `${apiEndPoint}/api/v1/imageData`
};
export default apiUrls;
