const localSettings = {
  API_END_POINT_URL: 'http://ke.lndo.site',
  APP_URL: 'http://localhost:3000'
};
  
const devSettings = {
  API_END_POINT_URL: 'https://ke.mybetasite.net',
  APP_URL: 'https://ke-app.mybetasite.net'
};

const prodSettings = {
  API_END_POINT_URL: 'https://webadmin.kellinyendodontics.com',
  APP_URL: 'https://kellinyendodontics.com'
};

const prepareSettings = () => {
	let settings;
	switch (document.location.hostname) {
		case 'kellinyendodontics.com':
			settings = prodSettings;
			break;
		case 'keapp.mybetasite.net':
			settings = devSettings;
			break;
		case 'ke.lndo.site':
		default:
			settings = localSettings;
	}
	return settings;
};
  
export default prepareSettings();
